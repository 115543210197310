@import 'settings';
@import 'util/util';

$darkblue: rgb(20,40,80);
$darkestblue: rgb(0,10,20);
$biege: rgb(210,190,170);
$biege-background: rgb(250,248,242);
$blue: rgb(170,200,230);
$blue-background: rgb(242,251,255);


@mixin scale-to-parent {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin menu-icon($size, $thickness, $color)  {
	display: block;
	width: $size;
  height: $thickness;
  position: relative;
  background: $color; 
	opacity: 1;

  &:before {
		content: '';
  	position: absolute;
  	left: 0;
  	top: -#{0.5*($size - $thickness)};
  	width: $size;
  	height: $thickness;
  	background:$color; 
  	transition: top 0.2s ease-out,
  	transform 0.2s ease-out;
	}

  &:after {
		content: '';
  	position: absolute;
  	left: 0;
  	top: #{0.5*($size - $thickness)};
  	width: $size;
  	height: $thickness;
  	background:$color; 
  	transition: top 0.2s ease-out,
  	transform 0.2s ease-out;
	}

	&.closed {
		background: transparent !important;

		&:before {
			top: 0;
			transform: rotate(45deg);
		}

		&:after {
			top: 0;
			transform: rotate(-45deg);
		}
	}
}

@mixin css-empty-triangle ($size, $thickness, $color, $direction) {
	width: $size;
	height: $size;
	position:absolute;
	z-index:5;
	border-top: $thickness solid $color;
	border-right: $thickness solid $color;
	cursor:pointer;
	transition:border-color 0.3s ease-out;
	opacity:1;
	margin-top:-1px;

	@if $direction == up {
	transform:rotate(315deg);
	top: calc(50% - 0.25*#{$size});
	} @else if $direction == right {
	top: calc(50% - 0.4*#{$size});
	transform:rotate(45deg);
	} @else if $direction == down {
	top: calc(50% - 0.75*#{$size});
	transform:rotate(135deg);
	} @else {
	top: calc(50% - 0.4*#{$size});
	transform:rotate(225deg);
	}
}

* {
	transition: all 0.3s ease-in-out;
}

html, body {
  position: relative;
  height: 100%;
}

figure {
	position: relative;
	& > div,
	& > a {
		display: block;
		position:absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	& > img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&.height25 {
		height: 0;
		padding-top:25%;
	}
	
	&.height50 {
		height: 0;
		padding-top:50%;
	}

	&.height75 {
		height: 0;
		padding-top:70%;
	}

	&.height100 {
		height: 0;
		padding-top:100%;
	}

	&.height130 {
		height: 0;
		padding-top:130%;
	}

	&.height150 {
		height: 0;
		padding-top:150%;
	}

	&.height400 {
		height: 250px !important;
		@include breakpoint(medium) {
			height: 400px !important;
		}
	}

}

.divider {
	margin-bottom: 50px;
	&.huge {
		margin-bottom: 150px;
	}
}

h4 {
	font-weight: bold;
	margin-bottom: 30px;
}

/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------      Main Page       --------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */

.off-canvas-content {
	height: 100%;
	&.mainpage {display: flex};
}

.off-canvas {
	background: $darkblue;
	height: 60px;
	.menu {
		list-style: none;
		margin: 0;
		li {
			display: block;
			float: left;
			width: 25%;
			margin: 0;
			text-align: center;
			a {
				line-height: 60px;
				color: $white;
				outline: 0;
				font-size: 14px;
				@include breakpoint(medium){
					font-size: 18px;
				}
				&:hover, &:active, &:focus {
					outline: 0;
				}
			}
		}
	}
}

.menu-button {
	margin: 0;
	width: 50px;
	position: relative;
	cursor: pointer;
	.menu-icon	{
		@include menu-icon(20px, 1px, $white);
		left: 50%;
		transform: translateX(-50%);
	}
	outline: 0;
	&:hover, &:active, &:focus {
		outline: 0;
	}
	&.inner {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		height: 50px;
		background: $darkblue;
	}
}



.top-bar	{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	background: rgba($darkblue,0.9);
	@include breakpoint(medium) {
		height: 60px;
	}
	@include breakpoint(large) {
		height: 100px;
	}

	.gerb {
		position:fixed;
		bottom: 10px;
		left: 0;
		display: flex;
		align-items: center;
		background: transparent;
		margin-left: 20px;
		@include breakpoint(medium) {
			bottom: 20px;
			width: 60%;
			height: 80px;
			background: url('../img/gerb.png') left center no-repeat;
		}
		@include breakpoint(large) {
			width: 30%;
			position: relative;
			bottom: auto;
			top: auto;
			height: 100px;
		}

		p {
			font-size: 13px;
			color: $white;
			padding-left: 0;
			line-height: 1.1;
			margin: 0;
			@include breakpoint(medium) {
				padding-left: 100px;
			}
		}
	}

	.title {
		display: flex;
		align-items: center;

		h1 {
			text-align: right;
			color: $white;
			margin: 0;
			padding: 0 20px;
			line-height: 1;
			font-size: 14px;
			line-height: 40px;
			@include breakpoint(medium) {
				font-size: 32px;
				padding: 0 50px 5px;
			}
			@include breakpoint(large) {
				font-size: 36px;
			}
			@include breakpoint(xlarge) {
				font-size: 44px;
			}
		}

		a {
			color: inherit;
			&:hover,&:active,&:focus {
				color: inherit;
			}
		}
	}

}

.mainpage-segments {
	width: 100%;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	@include breakpoint(medium) {
		flex-wrap: nowrap;
	}
	&:hover, &:active, &:focus {
		.mainpage-segment::before {
			background: rgba($darkestblue,0.6);
		}
	}
}

.mainpage-segment {
	position:relative;
	width: 100%;
	@include breakpoint(medium) {
		width: 33.3333333%;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 0.3s ease-in;

	&.nineteen {
		background-image: url('../img/19.jpg');
		background-position: 70% center;
	}
	&.twenty {
		background-image: url('../img/20.jpg');
		background-position: 70% center;
	}
	&.twenty-one {
		background-image: url('../img/21.jpg');
		background-position: 70% center;
	}
	&:before {
		content: '';
		display: block;
		@include scale-to-parent;
		background: rgba($darkestblue,0.1);
		transition: all 0.3s ease-in;
	}

	&:hover, &:active, &:focus {
		@include breakpoint(medium) {
			width: 70%;
		}
		&:before {
			background: rgba($darkestblue,0) !important;
		}
	}

	.button {
		display: block;
		position: relative;
		font-size: 16px;
		background: rgba($darkblue,0.7);
		border: 1px solid $white;
		font-weight: bold;
		color: $white;
		padding: 15px 20px;
		transition: all 0.3s ease-in;
		margin-top: 50px;

		@include breakpoint(medium) {
			font-size: 24px;
			padding: 25px 35px;
		}

		&:hover, &:active, &:focus {
			background: rgba($darkblue,0.95);
		}
	}
}


/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------     Inner Pages      --------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */



#pagination {
	background: $biege-background;
	height: 120px;
	position: fixed;
	bottom: -2px;
	left: 0;
	right: 0;
	z-index: 10;
	transition: all 0.1s ease-in;
	opacity: 0;
	transform: translateY(100px);
	display: flex;
	align-items: flex-end;
	box-shadow: 0 0 5px rgba(0,0,0,0.2);

	&.active {
		opacity: 1;
		transform: translateY(0);
	}

	.scale {
		width: 100%;
		border-top: 1px solid $biege;
		padding: 0 100px;

		ul {
			margin: 0 0 40px;
			width: 100%;
			list-style: none;
			position: relative;

			li {
				display: block;
				position: absolute;
				padding-left: 60px;
				height: 10px;
				
				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 1px;
					height: 10px;
					background: $biege;
				}
				&:last-of-type {
					border-top: 0;
				}
				a {
					position: absolute;
					display: block;
					top: 10px;
					left: 0;
					font-weight: bold;
					transform: translateX(-50%);
					color: $biege;
				}
			}
		}
	}

	.train {
		width: 100%;
		position: absolute;
		padding: 0 100px;
		bottom: 40px;
	}
}

.loco {
	height: 50px;
	transform: translateX(-100%);
	position: relative;
}

#nineteen {
	width: 600px;
	transition: all 0.8s ease-out;
	background: url(../img/19/train.png) center no-repeat;
	background-size: contain;
	background-position: right bottom;
}

#twenty {
	width: 800px;
	transition: all 0.4s ease-out;
	background: url(../img/20/train.png) center no-repeat;
	background-size: contain;
	background-position: right bottom;
}

#twentyone {
	width: 800px;
	transition: all 0.1s ease-out;
	background: url(../img/21/train.png) center no-repeat;
	background-size: contain;
	background-position: right bottom;
}

.heading-image {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	.parallax-background {
		position: absolute;
		@include scale-to-parent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&:before {
		content: '';
		display: block;
		@include scale-to-parent;
		background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0%,rgba(0,0,0,0.6) 100%);
		z-index: 1;
	}
	h1 {
		text-align: center;
		position: relative;
		color: $white;
		font-weight: bold;
		line-height: 1.1;
		font-size: 40px;
		@include breakpoint(medium){
			font-size: 64px;
		}
		z-index: 2;
		span {
			font-weight: normal;
			display: block;
			font-size: 30px;
			@include breakpoint(medium){
				font-size: 50px;
			}
		}
	}
}

.content {
	position: relative;
	padding: 0 0 150px;
	z-index: 5;
	background: $biege-background;

	&.twentyone {
		background: $biege-background;
	}

	h2 {
		font-weight: bold;
		margin-bottom: 30px;
	}
	.subheader {
		color: $black;
		font-size: 1em;
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: 20px;
		@include breakpoint(medium){
			font-size: 1.2em;
		}
	}
}

.chapter {
	padding-top: 150px;
}

.chapter-number {
	font-size: 120px;
	font-weight: bold;
	text-align: right;
	margin-right: 30px;
	margin-top: -30px;
	padding-top: 5px;
	line-height: 1;
	border-top: 10px solid $biege;
	color: $biege;
}

.gallery {
	@include xy-grid();
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 30px;

	.item {
		&.twelve {
			@include xy-cell(12,$gutters: 5px);
		}
		&.three {
			@include xy-cell(12,$gutters: 5px);
			@include breakpoint(medium) {
				@include xy-cell(3,$gutters: 5px);
			}
		}
		&.six {
			@include xy-cell(12,$gutters: 5px);
			@include breakpoint(medium) {
				@include xy-cell(6,$gutters: 5px);
			}
		}
		figure {
			height: 250px;
		}
	}
}

.caption {
	margin-top: 10px;
	font-size: 14px;
	line-height: 1.2;
}

.in-chapter-portrait {
	padding-right: 50px;
}

.twentyone {
	#pagination {
		background: $blue-background;
		.scale {
			border-top: 1px solid $blue;
			li {		
				&:before {
					background: $blue;
				}
				a {
					color: $blue;
				}
			}
		}
	}
	.content {
		background: $blue-background;
	}
	.chapter-number {
		border-top: 10px solid $blue;
		color: $blue;
	}
}
