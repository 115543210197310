@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Tinos:400,700");
/**
 * Foundation for Sites by ZURB
 * Version 6.4.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
@import url("https://fonts.googleapis.com/css?family=Tinos:400,700");
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 115%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Tinos", serif;
  font-weight: normal;
  line-height: 1.3;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  max-width: 65.21739rem;
  margin: 0 auto; }

.grid-container-padded {
  padding-left: 0.54348rem;
  padding-right: 0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container-padded {
      padding-left: 0.81522rem;
      padding-right: 0.81522rem; } }
  @media screen and (min-width: 65.21739em) {
    .grid-container-padded {
      padding-left: 0;
      padding-right: 0; } }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > small-shrink, .grid-x > small-full, .grid-x > small-1, .grid-x > small-2, .grid-x > small-3, .grid-x > small-4, .grid-x > small-5, .grid-x > small-6, .grid-x > small-7, .grid-x > small-8, .grid-x > small-9, .grid-x > small-10, .grid-x > small-11, .grid-x > small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > medium-shrink, .grid-x > medium-full, .grid-x > medium-1, .grid-x > medium-2, .grid-x > medium-3, .grid-x > medium-4, .grid-x > medium-5, .grid-x > medium-6, .grid-x > medium-7, .grid-x > medium-8, .grid-x > medium-9, .grid-x > medium-10, .grid-x > medium-11, .grid-x > medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > large-shrink, .grid-x > large-full, .grid-x > large-1, .grid-x > large-2, .grid-x > large-3, .grid-x > large-4, .grid-x > large-5, .grid-x > large-6, .grid-x > large-7, .grid-x > large-8, .grid-x > large-9, .grid-x > large-10, .grid-x > large-11, .grid-x > large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.54348rem;
  margin-right: -0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.81522rem;
      margin-right: -0.81522rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.08696rem);
    margin-left: 0.54348rem;
    margin-right: 0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.63043rem);
      margin-left: 0.81522rem;
      margin-right: 0.81522rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.08696rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.08696rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.08696rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.08696rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.08696rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.08696rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.08696rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.08696rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.08696rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.08696rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.08696rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.08696rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.63043rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.63043rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.63043rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.63043rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.63043rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.63043rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.63043rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.63043rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.63043rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.63043rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.63043rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.63043rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.63043rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.63043rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.63043rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.63043rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.63043rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.63043rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.63043rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.63043rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.63043rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.63043rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.63043rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.63043rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.63043rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.63043rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.63043rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.63043rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.63043rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.63043rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.63043rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.63043rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.63043rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.63043rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.63043rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.63043rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.54348rem;
  margin-left: -0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.81522rem;
      margin-left: -0.81522rem; } }

.grid-padding-x > .cell {
  padding-right: 0.54348rem;
  padding-left: 0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.81522rem;
      padding-left: 0.81522rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.08696rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.08696rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.08696rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.08696rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.08696rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.08696rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.08696rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.08696rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.63043rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.63043rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.63043rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.63043rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.63043rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.63043rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.63043rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.63043rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.63043rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.63043rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.63043rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.63043rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.63043rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.63043rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.63043rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.63043rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.63043rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.63043rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.63043rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.63043rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.63043rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.63043rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.63043rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.63043rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  .small-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .small-margin-collapse > .medium-3 {
    width: 25%; }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .small-margin-collapse > .medium-6 {
    width: 50%; }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .small-margin-collapse > .medium-9 {
    width: 75%; }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .small-margin-collapse > .medium-12 {
    width: 100%; }
  .small-margin-collapse > .large-1 {
    width: 8.33333%; }
  .small-margin-collapse > .large-2 {
    width: 16.66667%; }
  .small-margin-collapse > .large-3 {
    width: 25%; }
  .small-margin-collapse > .large-4 {
    width: 33.33333%; }
  .small-margin-collapse > .large-5 {
    width: 41.66667%; }
  .small-margin-collapse > .large-6 {
    width: 50%; }
  .small-margin-collapse > .large-7 {
    width: 58.33333%; }
  .small-margin-collapse > .large-8 {
    width: 66.66667%; }
  .small-margin-collapse > .large-9 {
    width: 75%; }
  .small-margin-collapse > .large-10 {
    width: 83.33333%; }
  .small-margin-collapse > .large-11 {
    width: 91.66667%; }
  .small-margin-collapse > .large-12 {
    width: 100%; }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .medium-margin-collapse > .small-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .small-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .small-3 {
      width: 25%; }
    .medium-margin-collapse > .small-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .small-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .small-6 {
      width: 50%; }
    .medium-margin-collapse > .small-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .small-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .small-9 {
      width: 75%; }
    .medium-margin-collapse > .small-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .small-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .small-12 {
      width: 100%; }
    .medium-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .medium-3 {
      width: 25%; }
    .medium-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .medium-6 {
      width: 50%; }
    .medium-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .medium-9 {
      width: 75%; }
    .medium-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .medium-12 {
      width: 100%; }
    .medium-margin-collapse > .large-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .large-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .large-3 {
      width: 25%; }
    .medium-margin-collapse > .large-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .large-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .large-6 {
      width: 50%; }
    .medium-margin-collapse > .large-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .large-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .large-9 {
      width: 75%; }
    .medium-margin-collapse > .large-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .large-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .large-12 {
      width: 100%; }
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .large-margin-collapse > .small-1 {
      width: 8.33333%; }
    .large-margin-collapse > .small-2 {
      width: 16.66667%; }
    .large-margin-collapse > .small-3 {
      width: 25%; }
    .large-margin-collapse > .small-4 {
      width: 33.33333%; }
    .large-margin-collapse > .small-5 {
      width: 41.66667%; }
    .large-margin-collapse > .small-6 {
      width: 50%; }
    .large-margin-collapse > .small-7 {
      width: 58.33333%; }
    .large-margin-collapse > .small-8 {
      width: 66.66667%; }
    .large-margin-collapse > .small-9 {
      width: 75%; }
    .large-margin-collapse > .small-10 {
      width: 83.33333%; }
    .large-margin-collapse > .small-11 {
      width: 91.66667%; }
    .large-margin-collapse > .small-12 {
      width: 100%; }
    .large-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .large-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .large-margin-collapse > .medium-3 {
      width: 25%; }
    .large-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .large-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .large-margin-collapse > .medium-6 {
      width: 50%; }
    .large-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .large-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .large-margin-collapse > .medium-9 {
      width: 75%; }
    .large-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .large-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .large-margin-collapse > .medium-12 {
      width: 100%; }
    .large-margin-collapse > .large-1 {
      width: 8.33333%; }
    .large-margin-collapse > .large-2 {
      width: 16.66667%; }
    .large-margin-collapse > .large-3 {
      width: 25%; }
    .large-margin-collapse > .large-4 {
      width: 33.33333%; }
    .large-margin-collapse > .large-5 {
      width: 41.66667%; }
    .large-margin-collapse > .large-6 {
      width: 50%; }
    .large-margin-collapse > .large-7 {
      width: 58.33333%; }
    .large-margin-collapse > .large-8 {
      width: 66.66667%; }
    .large-margin-collapse > .large-9 {
      width: 75%; }
    .large-margin-collapse > .large-10 {
      width: 83.33333%; }
    .large-margin-collapse > .large-11 {
      width: 91.66667%; }
    .large-margin-collapse > .large-12 {
      width: 100%; }
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.54348rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.54348rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.54348rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.54348rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.54348rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.54348rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.54348rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.54348rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.54348rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.54348rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.54348rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.54348rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.81522rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.81522rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.81522rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.81522rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.81522rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.81522rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.81522rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.81522rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.81522rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.81522rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.81522rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.81522rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.81522rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.81522rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.81522rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.81522rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.81522rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.81522rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.81522rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.81522rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.81522rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.81522rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.81522rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.81522rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > small-shrink, .grid-y > small-full, .grid-y > small-1, .grid-y > small-2, .grid-y > small-3, .grid-y > small-4, .grid-y > small-5, .grid-y > small-6, .grid-y > small-7, .grid-y > small-8, .grid-y > small-9, .grid-y > small-10, .grid-y > small-11, .grid-y > small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > medium-shrink, .grid-y > medium-full, .grid-y > medium-1, .grid-y > medium-2, .grid-y > medium-3, .grid-y > medium-4, .grid-y > medium-5, .grid-y > medium-6, .grid-y > medium-7, .grid-y > medium-8, .grid-y > medium-9, .grid-y > medium-10, .grid-y > medium-11, .grid-y > medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > large-shrink, .grid-y > large-full, .grid-y > large-1, .grid-y > large-2, .grid-y > large-3, .grid-y > large-4, .grid-y > large-5, .grid-y > large-6, .grid-y > large-7, .grid-y > large-8, .grid-y > large-9, .grid-y > large-10, .grid-y > large-11, .grid-y > large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.54348rem;
  margin-bottom: -0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.81522rem;
      margin-bottom: -0.81522rem; } }

.grid-padding-y > .cell {
  padding-top: 0.54348rem;
  padding-bottom: 0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.81522rem;
      padding-bottom: 0.81522rem; } }

.grid-margin-y {
  margin-top: -0.54348rem;
  margin-bottom: -0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.81522rem;
      margin-bottom: -0.81522rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.08696rem);
    margin-top: 0.54348rem;
    margin-bottom: 0.54348rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.63043rem);
      margin-top: 0.81522rem;
      margin-bottom: 0.81522rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.08696rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.08696rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.08696rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.08696rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.08696rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.08696rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.08696rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.08696rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.08696rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.08696rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.08696rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.08696rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.63043rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.63043rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.63043rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.63043rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.63043rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.63043rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.63043rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.63043rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.63043rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.63043rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.63043rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.63043rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.63043rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.63043rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.63043rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.63043rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.63043rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.63043rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.63043rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.63043rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.63043rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.63043rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.63043rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.63043rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.63043rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.63043rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.63043rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.63043rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.63043rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.63043rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.63043rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.63043rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.63043rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.63043rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.63043rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.63043rem); } }

.grid-frame {
  width: 100vw;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.4;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Tinos", serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.84783rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.52174rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.03261rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 0.97826rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 0.92391rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 0.86957rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2.6087rem; }
  h2 {
    font-size: 2.17391rem; }
  h3 {
    font-size: 1.68478rem; }
  h4 {
    font-size: 1.3587rem; }
  h5 {
    font-size: 1.08696rem; }
  h6 {
    font-size: 0.86957rem; } }

a {
  line-height: inherit;
  color: #005596;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #004981; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 65.21739rem;
  height: 0;
  margin: 1.08696rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.4; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.48913rem 1.08696rem 0 1.03261rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.4;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.70652rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.1087rem 0.27174rem 0.05435rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.1087rem 0.21739rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 143.75%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #005596;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #004880;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #005596;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #004478;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #005596;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #005596;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #005596;
    color: #005596; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #002b4b;
      color: #002b4b; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #005596;
        color: #005596; }
    .button.hollow.primary {
      border: 1px solid #005596;
      color: #005596; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #002b4b;
        color: #002b4b; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #005596;
          color: #005596; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #005596;
    color: #005596; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #002b4b;
      color: #002b4b; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #005596;
        color: #005596; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #005596;
      color: #005596; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #002b4b;
        color: #002b4b; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #005596;
          color: #005596; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #005596; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #005596; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 0 rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 0 rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  transform: translateX(-60px);
  overflow-y: auto; }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-60px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(60px); }
  .position-left.is-transition-push {
    box-shadow: inset 0 0 0 0 rgba(10, 10, 10, 0); }

.position-right {
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  transform: translateX(60px);
  overflow-y: auto; }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(60px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-60px); }
  .position-right.is-transition-push {
    box-shadow: inset 0 0 0 0 rgba(10, 10, 10, 0); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  transform: translateY(-60px);
  overflow-x: auto; }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-60px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(60px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 0 0 0 rgba(10, 10, 10, 0); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  transform: translateY(60px);
  overflow-x: auto; }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(60px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-60px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 0 0 0 rgba(10, 10, 10, 0); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 60px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 60px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 60px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 60px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 60px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 60px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 60px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 60px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 60px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 60px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 60px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 60px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 60px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 60px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 60px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 60px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

* {
  transition: all 0.3s ease-in-out; }

html, body {
  position: relative;
  height: 100%; }

figure {
  position: relative; }
  figure > div,
  figure > a {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  figure > img {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  figure.height25 {
    height: 0;
    padding-top: 25%; }
  figure.height50 {
    height: 0;
    padding-top: 50%; }
  figure.height75 {
    height: 0;
    padding-top: 70%; }
  figure.height100 {
    height: 0;
    padding-top: 100%; }
  figure.height130 {
    height: 0;
    padding-top: 130%; }
  figure.height150 {
    height: 0;
    padding-top: 150%; }
  figure.height400 {
    height: 250px !important; }
    @media print, screen and (min-width: 40em) {
      figure.height400 {
        height: 400px !important; } }

.divider {
  margin-bottom: 50px; }
  .divider.huge {
    margin-bottom: 150px; }

h4 {
  font-weight: bold;
  margin-bottom: 30px; }

/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------      Main Page       --------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
.off-canvas-content {
  height: 100%; }
  .off-canvas-content.mainpage {
    display: flex; }

.off-canvas {
  background: #142850;
  height: 60px; }
  .off-canvas .menu {
    list-style: none;
    margin: 0; }
    .off-canvas .menu li {
      display: block;
      float: left;
      width: 25%;
      margin: 0;
      text-align: center; }
      .off-canvas .menu li a {
        line-height: 60px;
        color: #fefefe;
        outline: 0;
        font-size: 14px; }
        @media print, screen and (min-width: 40em) {
          .off-canvas .menu li a {
            font-size: 18px; } }
        .off-canvas .menu li a:hover, .off-canvas .menu li a:active, .off-canvas .menu li a:focus {
          outline: 0; }

.menu-button {
  margin: 0;
  width: 50px;
  position: relative;
  cursor: pointer;
  outline: 0; }
  .menu-button .menu-icon {
    display: block;
    width: 20px;
    height: 1px;
    position: relative;
    background: #fefefe;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%); }
    .menu-button .menu-icon:before {
      content: '';
      position: absolute;
      left: 0;
      top: -9.5px;
      width: 20px;
      height: 1px;
      background: #fefefe;
      transition: top 0.2s ease-out, transform 0.2s ease-out; }
    .menu-button .menu-icon:after {
      content: '';
      position: absolute;
      left: 0;
      top: 9.5px;
      width: 20px;
      height: 1px;
      background: #fefefe;
      transition: top 0.2s ease-out, transform 0.2s ease-out; }
    .menu-button .menu-icon.closed {
      background: transparent !important; }
      .menu-button .menu-icon.closed:before {
        top: 0;
        transform: rotate(45deg); }
      .menu-button .menu-icon.closed:after {
        top: 0;
        transform: rotate(-45deg); }
  .menu-button:hover, .menu-button:active, .menu-button:focus {
    outline: 0; }
  .menu-button.inner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 50px;
    background: #142850; }

.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  background: rgba(20, 40, 80, 0.9); }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      height: 60px; } }
  @media print, screen and (min-width: 64em) {
    .top-bar {
      height: 100px; } }
  .top-bar .gerb {
    position: fixed;
    bottom: 10px;
    left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    margin-left: 20px; }
    @media print, screen and (min-width: 40em) {
      .top-bar .gerb {
        bottom: 20px;
        width: 60%;
        height: 80px;
        background: url("../img/gerb.png") left center no-repeat; } }
    @media print, screen and (min-width: 64em) {
      .top-bar .gerb {
        width: 30%;
        position: relative;
        bottom: auto;
        top: auto;
        height: 100px; } }
    .top-bar .gerb p {
      font-size: 13px;
      color: #fefefe;
      padding-left: 0;
      line-height: 1.1;
      margin: 0; }
      @media print, screen and (min-width: 40em) {
        .top-bar .gerb p {
          padding-left: 100px; } }
  .top-bar .title {
    display: flex;
    align-items: center; }
    .top-bar .title h1 {
      text-align: right;
      color: #fefefe;
      margin: 0;
      padding: 0 20px;
      line-height: 1;
      font-size: 14px;
      line-height: 40px; }
      @media print, screen and (min-width: 40em) {
        .top-bar .title h1 {
          font-size: 32px;
          padding: 0 50px 5px; } }
      @media print, screen and (min-width: 64em) {
        .top-bar .title h1 {
          font-size: 36px; } }
      @media screen and (min-width: 75em) {
        .top-bar .title h1 {
          font-size: 44px; } }
    .top-bar .title a {
      color: inherit; }
      .top-bar .title a:hover, .top-bar .title a:active, .top-bar .title a:focus {
        color: inherit; }

.mainpage-segments {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media print, screen and (min-width: 40em) {
    .mainpage-segments {
      flex-wrap: nowrap; } }
  .mainpage-segments:hover .mainpage-segment::before, .mainpage-segments:active .mainpage-segment::before, .mainpage-segments:focus .mainpage-segment::before {
    background: rgba(0, 10, 20, 0.6); }

.mainpage-segment {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in; }
  @media print, screen and (min-width: 40em) {
    .mainpage-segment {
      width: 33.3333333%; } }
  .mainpage-segment.nineteen {
    background-image: url("../img/19.jpg");
    background-position: 70% center; }
  .mainpage-segment.twenty {
    background-image: url("../img/20.jpg");
    background-position: 70% center; }
  .mainpage-segment.twenty-one {
    background-image: url("../img/21.jpg");
    background-position: 70% center; }
  .mainpage-segment:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 10, 20, 0.1);
    transition: all 0.3s ease-in; }
  @media print, screen and (min-width: 40em) {
    .mainpage-segment:hover, .mainpage-segment:active, .mainpage-segment:focus {
      width: 70%; } }
  .mainpage-segment:hover:before, .mainpage-segment:active:before, .mainpage-segment:focus:before {
    background: rgba(0, 10, 20, 0) !important; }
  .mainpage-segment .button {
    display: block;
    position: relative;
    font-size: 16px;
    background: rgba(20, 40, 80, 0.7);
    border: 1px solid #fefefe;
    font-weight: bold;
    color: #fefefe;
    padding: 15px 20px;
    transition: all 0.3s ease-in;
    margin-top: 50px; }
    @media print, screen and (min-width: 40em) {
      .mainpage-segment .button {
        font-size: 24px;
        padding: 25px 35px; } }
    .mainpage-segment .button:hover, .mainpage-segment .button:active, .mainpage-segment .button:focus {
      background: rgba(20, 40, 80, 0.95); }

/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------     Inner Pages      --------------------------------- */
/* --------------------------------                      --------------------------------- */
/* --------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------- */
#pagination {
  background: #faf8f2;
  height: 120px;
  position: fixed;
  bottom: -2px;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.1s ease-in;
  opacity: 0;
  transform: translateY(100px);
  display: flex;
  align-items: flex-end;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  #pagination.active {
    opacity: 1;
    transform: translateY(0); }
  #pagination .scale {
    width: 100%;
    border-top: 1px solid #d2beaa;
    padding: 0 100px; }
    #pagination .scale ul {
      margin: 0 0 40px;
      width: 100%;
      list-style: none;
      position: relative; }
      #pagination .scale ul li {
        display: block;
        position: absolute;
        padding-left: 60px;
        height: 10px; }
        #pagination .scale ul li:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 10px;
          background: #d2beaa; }
        #pagination .scale ul li:last-of-type {
          border-top: 0; }
        #pagination .scale ul li a {
          position: absolute;
          display: block;
          top: 10px;
          left: 0;
          font-weight: bold;
          transform: translateX(-50%);
          color: #d2beaa; }
  #pagination .train {
    width: 100%;
    position: absolute;
    padding: 0 100px;
    bottom: 40px; }

.loco {
  height: 50px;
  transform: translateX(-100%);
  position: relative; }

#nineteen {
  width: 600px;
  transition: all 0.8s ease-out;
  background: url(../img/19/train.png) center no-repeat;
  background-size: contain;
  background-position: right bottom; }

#twenty {
  width: 800px;
  transition: all 0.4s ease-out;
  background: url(../img/20/train.png) center no-repeat;
  background-size: contain;
  background-position: right bottom; }

#twentyone {
  width: 800px;
  transition: all 0.1s ease-out;
  background: url(../img/21/train.png) center no-repeat;
  background-size: contain;
  background-position: right bottom; }

.heading-image {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex; }
  .heading-image .parallax-background {
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; }
  .heading-image:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 1; }
  .heading-image h1 {
    text-align: center;
    position: relative;
    color: #fefefe;
    font-weight: bold;
    line-height: 1.1;
    font-size: 40px;
    z-index: 2; }
    @media print, screen and (min-width: 40em) {
      .heading-image h1 {
        font-size: 64px; } }
    .heading-image h1 span {
      font-weight: normal;
      display: block;
      font-size: 30px; }
      @media print, screen and (min-width: 40em) {
        .heading-image h1 span {
          font-size: 50px; } }

.content {
  position: relative;
  padding: 0 0 150px;
  z-index: 5;
  background: #faf8f2; }
  .content.twentyone {
    background: #faf8f2; }
  .content h2 {
    font-weight: bold;
    margin-bottom: 30px; }
  .content .subheader {
    color: #0a0a0a;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 40em) {
      .content .subheader {
        font-size: 1.2em; } }

.chapter {
  padding-top: 150px; }

.chapter-number {
  font-size: 120px;
  font-weight: bold;
  text-align: right;
  margin-right: 30px;
  margin-top: -30px;
  padding-top: 5px;
  line-height: 1;
  border-top: 10px solid #d2beaa;
  color: #d2beaa; }

.gallery {
  display: flex;
  flex-flow: row wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 30px; }
  .gallery .item.twelve {
    width: calc(100% - 0.27174rem);
    margin-right: 0.13587rem;
    margin-left: 0.13587rem; }
  .gallery .item.three {
    width: calc(100% - 0.27174rem);
    margin-right: 0.13587rem;
    margin-left: 0.13587rem; }
    @media print, screen and (min-width: 40em) {
      .gallery .item.three {
        width: calc(25% - 0.27174rem);
        margin-right: 0.13587rem;
        margin-left: 0.13587rem; } }
  .gallery .item.six {
    width: calc(100% - 0.27174rem);
    margin-right: 0.13587rem;
    margin-left: 0.13587rem; }
    @media print, screen and (min-width: 40em) {
      .gallery .item.six {
        width: calc(50% - 0.27174rem);
        margin-right: 0.13587rem;
        margin-left: 0.13587rem; } }
  .gallery .item figure {
    height: 250px; }

.caption {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2; }

.in-chapter-portrait {
  padding-right: 50px; }

.twentyone #pagination {
  background: #f2fbff; }
  .twentyone #pagination .scale {
    border-top: 1px solid #aac8e6; }
    .twentyone #pagination .scale li:before {
      background: #aac8e6; }
    .twentyone #pagination .scale li a {
      color: #aac8e6; }

.twentyone .content {
  background: #f2fbff; }

.twentyone .chapter-number {
  border-top: 10px solid #aac8e6;
  color: #aac8e6; }
